.twitter-tweet {
  margin: -13px auto !important;
  min-height: 250px;
}

blockquote {
  display: none;
}

// .leaflet-popup-content:has(.twitter-tweet) {
//   margin: 0 !important;
//   margin-bottom: 11px !important;
// }

.leaflet-popup-content:has(.twitter-tweet) {
  --scrollbar-bg: #cfd8dc;
  --thumb-bg: #90a4ae;
  margin: 11px 0 !important;
  overflow-y: auto;
  max-height: 360px;
}

.twitter-wrapper {
  --scrollbar-bg: #cfd8dc;
  --thumb-bg: #90a4ae;
  margin: 11px 0 !important;
  overflow-y: auto;
  max-height: 360px;
}

// .scroll-track {
//   &::-webkit-scrollbar {
//     width: 11px;
//   }
//   &::-webkit-scrollbar-track {
//     background: var(--scrollbar-bg);
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: var(--thumb-bg);
//     border-radius: 6px;
//     border: 3px solid var(--scrollbar-bg);
//   }
// }

// -------------------------------

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.timeline-wrapper {
  padding: 0px;
  border-radius: 12px;
  margin: 5px;
}

.timeline-item {
  background: #fff;

  border-radius: 3px;
  padding: 12px;

  margin: 0 auto;
  max-width: 472px;
  min-height: 200px;
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eeeeee;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
  box-sizing: border-box;
}

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}
