#map {
  --heightHeader: calc(var(--header-height, 76px) + var(--custom-info-height));
  @media (max-width: 991px) {
    --heightHeader: calc(
      var(--header-height) + var(--watch-partner, 0px) +
        var(--custom-info-height)
    );
  }

  position: fixed;
  top: calc(var(--header-height) + var(--custom-info-height));
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100% - var(--heightHeader, 76px));
  height: calc(100% - var(--heightHeader, 76px));

  background: var(--bs-white);

  @media (max-width: 991px) {
    top: auto;
  }
}

@media (max-width: 991px) {
  .bg-dark {
    display: none;
  }
}

.map-container {
  overflow: hidden;
}

.maptiler-element {
  position: absolute;
  left: 20px;
  bottom: 5px;
  z-index: 999;
}

.leaflet-control-attribution a svg {
  display: none !important;
}
