.sidebar {
  position: fixed;
  bottom: 0;
  z-index: 1002;
  width: 100%;
  max-width: 500px;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.8);
  line-height: 80%;
  text-align: center;

  @media (max-width: 430px) {
    .menu-text {
      font-size: 9px;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
    border-radius: 0;
    margin-bottom: 0;
    left: 0;
  }

  .sidebar-location {
    display: var(--has-touch-screen);
  }
}

.menu-item {
  user-select: none;
  display: flex;
  cursor: pointer;
  padding: 10px;
  @media (max-width: 430px) {
    padding: 5px;
  }
  color: var(--bs-white);
  align-items: center;
  font-size: 80%;
  align-self: center;
  transition: color 0.5s ease-out;

  svg {
    pointer-events: none;
    width: 35px;
    height: 35px;
    @media (max-width: 450px) {
      width: 25px;
      height: 25px;
    }
    fill: var(--bs-white);
    transition: fill 0.5s ease-out;
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transition: fill 0.4s ease-in;
        fill: var(--bs-warning);
      }

      .menu-text {
        transition: color 0.4s ease-in;
        color: var(--bs-warning);
      }
    }
  }
}

.menu-text {
  word-wrap: break-word;
}

.fullscreen-is-active {
  overflow: hidden;

  .sidebar-fullscreen-exit {
    display: flex;
    @media (max-width: 992px) {
      display: none;
    }
  }

  .sidebar-fullscreen-in {
    display: none;
  }

  #map {
    position: fixed;
    width: 100%;
    border: 0;
    height: 100%;
    z-index: 9;

    @media (max-width: 992px) {
      height: calc(100vh - 50px);
    }
  }

  @media (max-width: 991px) {
    .navbar {
      z-index: 999;
    }
  }

  @media (min-width: 992px) {
    .navbar {
      z-index: 0;
    }
  }

  .sidebar {
    position: fixed;
  }

  .maptiler-element {
    position: fixed;
    left: 10px;
    bottom: 5px;
  }
}

.location-is-active {
  .sidebar-location {
    svg {
      fill: var(--bs-blue);
    }
    .menu-text {
      color: var(--active-sidebar);
    }
  }
}

.parnter-quickbar-slider img {
  max-height: 38px;
}

.slider-wrapper-content {
  padding-right: 40px;
}
// title
.slider-title {
  position: relative;
  border-radius: 0 10px 0 0;
  background-color: var(--main-color-bg, #393939);
  color: var(--bs-white);
  font-size: 14px;
  height: 60px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    border-radius: 0;
  }
}

.close-slider {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  .icon {
    pointer-events: none;
    background: none;
    width: 30px;
    height: 30px;
    fill: var(--bs-white);
  }
}

.slider-content {
  overflow: auto;
  height: calc(100% - var(--sidebar-height));
}

.button-close-slider {
  position: absolute;
  width: 30px;
  right: 5px;
  z-index: 999;
}

.title-slider {
  position: absolute;
  background: var(--main-color-bg);
  top: -26px;
  left: 0;
  width: auto;
  padding: 5px 15px 0 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.parnter-quickbar-slider {
  margin-left: auto;
  margin-right: 0;
}

.logo-slider {
  img {
    max-height: 38px;
  }
}

.logo-slider {
  // border-top-left-radius: 10px;
  img {
    max-height: 60px;
    max-width: 120px;
  }
}
